import React from 'react'

const Errorpage = () =>  {
    return (
        <h1>
            Something went wrong :(...
        </h1>
    )
}

export default Errorpage
